import React from 'react'
import emojiRegex from 'emoji-regex'
import { isArray, isEmpty } from 'lodash'
import { compile } from 'path-to-regexp'
import currency from 'currency.js'
import { formatRates } from './reserveUtils'

export const formatTenDigitPhoneNumber = phoneStr =>
  phoneStr ? phoneStr.replace(/\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/g, '($1) $2-$3') : phoneStr

export const formatCreditTransactionType = primaryTag => {
  if (
    primaryTag?.relationType === 'TESTING' ||
    primaryTag?.relationType === 'ZENDESK' ||
    primaryTag?.relationType === 'JIRA_CONWEB'
  ) {
    return 'Foodsby Internal'
  } else if (primaryTag?.relationType === 'USER_ACHIEVEMENT') {
    const note = primaryTag?.note?.toUpperCase()
    if (note === 'DAILY LINK') {
      return 'Daily Link Credit'
    } else if (note.startsWith('REFERRAL')) {
      return 'Referral Credit'
    } else {
      return 'Achievement'
    }
  } else {
    return (
      primaryTag?.relationType
        ?.toLowerCase()
        ?.split('_')
        ?.map(part => {
          return part.charAt(0).toUpperCase() + part.slice(1)
        })
        ?.join(' ') || ''
    )
  }
}

export const convertOrderDetailItemsToCartMenuItems = orderDetailItems =>
  orderDetailItems.map(item => ({
    ...item,
    displayText: item.displayText,
    fullSubTotal: item.fullSubTotal,
    itemSubTotal: item.subTotal,
    menuItem: {
      ...item,
      displayText: item.displayText,
    },
    modifierSubTotal: item.modifierSubTotal,
    modifiers: item.modifiers.map(mod => ({
      answers: mod.answers.map(ans => ({
        menuItem: {
          displayText: ans.displayText,
          price: ans.price,
        },
      })),
    })),
    specialInstructions: item.specialInstructions,
  }))

export const formatUrl = (
  path,
  { dropoffId = 0, locationId = 0, orderId = 0, ...rest },
  queryParams = [],
) => {
  const toPath = compile(path)
  const result = toPath({ dropoffId, locationId, orderId, ...rest })

  if (isArray(queryParams) && !isEmpty(queryParams)) {
    const query = queryParams
      .filter(({ value }) => value)
      .map(({ key, value }) => `${key}=${value}`)
      .join('&')

    return `${result}?${query}`
  }

  return result
}

/**
 * @deprecated in favor of `copy` from `copy-to-clipboard`
 */
export const copyToClipboard = string => {
  let textarea

  try {
    textarea = document.createElement('textarea')
    textarea.setAttribute('readonly', true)
    textarea.setAttribute('contenteditable', true)
    textarea.style.position = 'fixed' // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string

    document.body.appendChild(textarea)

    textarea.focus()
    textarea.select()

    const range = document.createRange()
    range.selectNodeContents(textarea)

    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)

    textarea.setSelectionRange(0, textarea.value.length)
    document.execCommand('copy')
  } catch (err) {
    console.error(err)
  } finally {
    document.body.removeChild(textarea)
  }
}

// If an emoji exists in the name it returns that
// Otherwise returns the initials
export const getInitialsOrEmoji = (name = '') => {
  const regex = emojiRegex()
  const match = regex.exec(name)
  if (match && match.length) {
    return match[0]
  } else {
    return name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g)
  }
}

export const stripEmoji = string => {
  if (!string) {
    return undefined
  }

  const regex = emojiRegex()
  const match = regex.exec(string)
  return match ? string.replace(match[0], '') : string
}

export const formatAddress = ({ city, state, street, zip }) =>
  [street, city, state, zip].filter(Boolean).join(', ')

export const formatSelectedAddress = ({ deliveryLine1, lastLine }) =>
  [deliveryLine1, lastLine].filter(Boolean).join(', ')

export const formatCurrencyInCents = amountInCents => {
  return currency(amountInCents / 100.0, { formatWithSymbol: true }).format()
}

export function setUndefinedItemSubtotalToZero(value) {
  return Number.isNaN(value) || value === undefined ? 0 : value
}

export function formatRewardsPercentage(dropoffRewards) {
  if (dropoffRewards === null || dropoffRewards === 0) {
    return
  } else {
    let thisAmount = dropoffRewards * 100
    const factor = Math.pow(10, 2)
    const percentage = Math.trunc(thisAmount * factor) / factor
    return percentage
  }
}

export function formatFriendsOrderedCount(friendsOrdered) {
  if (!friendsOrdered) {
    return 0
  }
  return friendsOrdered.length
}

export function formatAverageExpectedOrders(averageExpectedOrders) {
  if (averageExpectedOrders == null) {
    return '1 - 3'
  }

  const orders = Math.round(averageExpectedOrders * 10) / 10

  if (orders <= 2.9) {
    return '1 - 3'
  } else if (orders <= 4.9) {
    return '3 - 5'
  } else if (orders <= 9.9) {
    return '5 - 10'
  } else {
    return '10+'
  }
}

export function createRewardsHoverMessage(
  currentOrderCount,
  averageExpectedOrders,
  classNames,
  numberOfOrdersPerRewardsPercentage,
) {
  let message

  if (classNames !== undefined && classNames.includes('is-new')) {
    message = (
      <div>
        <p style={{ fontWeight: 500 }}>Delivery Rewards</p>
        <div style={{ height: '5px' }} />
        <p>This restaurant is new. Currently, {numberOfCustomersMessage(currentOrderCount)}</p>
        <div style={{ height: '5px' }} />
        {formatRates(numberOfOrdersPerRewardsPercentage)}
      </div>
    )
  } else {
    message = (
      <div>
        <p style={{ fontWeight: 500 }}>Delivery Rewards</p>
        <div style={{ height: '5px' }} />
        <p>
          This restaurant averages <b>{averageExpectedOrders}</b> orders. Currently,{' '}
          {numberOfCustomersMessage(currentOrderCount)}
        </p>
        <div style={{ height: '5px' }} />
        {formatRates(numberOfOrdersPerRewardsPercentage)}
      </div>
    )
  }

  return message
}

export function createReserveRewardsHoverMessage(averageExpectedOrders, reserveRewards) {
  let tier

  if (averageExpectedOrders === '1 - 3') {
    tier = formatRates(reserveRewards[0])
  } else if (averageExpectedOrders === '3 - 5') {
    tier = formatRates(reserveRewards[1])
  } else if (averageExpectedOrders === '5 - 10' || averageExpectedOrders === '10+') {
    tier = formatRates(reserveRewards[2])
  } else {
    tier = null
  }

  const message = (
    <div>
      <p style={{ fontWeight: 500 }}>Delivery Rewards</p>
      <div style={{ height: '5px' }} />
      <p>
        This restaurant averages <b>{averageExpectedOrders}</b> orders at this location.
      </p>
      <div style={{ height: '5px' }} />
      {tier}
    </div>
  )
  return message
}

function numberOfCustomersMessage(currentOrderCount) {
  if (currentOrderCount === 1) {
    return (
      <>
        <b>1</b> customer has ordered.
      </>
    )
  }
  return (
    <>
      <b>{currentOrderCount}</b> customers have ordered.
    </>
  )
}

export function shuffleStores(stores) {
  for (let i = stores.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[stores[i], stores[j]] = [stores[j], stores[i]]
  }
  return stores
}
