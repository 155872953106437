import React, { useEffect } from 'react'
import { Box, Grid, Hidden, Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import { bool, number, object, string } from 'yup'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { find, isNil } from 'lodash'
import { connect } from 'react-redux'

import { checkoutOrder, initializePayment } from '../../api/api'
import PanelHeader from '../common/PanelHeader'
import {
  applyCouponStart,
  applyCreditStart,
  checkoutStart,
  checkoutComplete,
  checkoutRejected,
  resetCheckoutError,
} from '../../redux/modules/checkout'
import { loadCheckoutCartStart } from '../../redux/modules/cart'
import { toggleDeliveryInstructionsDialog } from '../../redux/modules/location'
import {
  selectProfile,
  selectCardPaymentDetail,
  selectCurrentSavedLocation,
  selectSuggestedCredits,
  selectSelectedCard,
  selectSaveCard,
  selectAllPaymentMethods,
  selectCurrentLocationWithAddress,
  selectPaymentMethodsIsLoading,
} from '../../redux/modules/selectors'
import { phoneRegExp } from '../common/InputMasks'
import { loadIsSubscribedStart } from '../../redux/modules/subscriptions'
import { isNewPaymentMethod, NEW_PAYMENT_METHOD_ID } from '../../util/paymentMethods'
import CheckoutDeliveryDetails from '../common/CheckoutDeliveryDetails'
import { themeConWeb } from '../../util/modernThemeConweb'
import { profileRoute } from '../../routes/routes'
import BillingInfo from './BillingInfo'
import Coupon from './Coupon'
import Credit from './Credit'
import Gifts from './Gift'
import UserInfo from './UserInfo'
import CateringDeliveryInstructions from './CateringDeliveryInstructions'
import CateringUtensils from './CateringUtensils'

const useStyles = makeStyles(theme => ({
  manageCards: {
    fontSize: `${themeConWeb.fontSizes.base}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const CateringCheckoutForm = ({
  // Props from parent
  dropoffId,
  cateringDeliveryDate,
  cateringDeliveryTime,

  // Props from redux
  deliveryDropoff,
  isDeliveryDropoffLoading,
  location,
  profile,
  applyCouponStart,
  couponUsage,
  creditUsage,
  applyCreditStart,
  resetCheckoutError,
  availableCredits,
  checkoutCart,
  cardPaymentDetail,
  checkoutStart,
  checkoutComplete,
  checkoutRejected,
  waitingForProcessed,
  selectedCard,
  shouldSaveCard,
  errorApplyingCoupon,
  paymentMethods,
  paymentMethodsError,
  errorApplyingCredit,
  curSavedLocation,
  isAvailableCreditsLoading,
  isCheckoutCartLoading,
  isCouponApplying,
  isCreditApplying,
  loadCheckoutCartStart,
  storedCurrencyUsage,
  suggestedCredits,
  isPaymentMethodsLoading,
}) => {
  const elements = useElements()
  const stripe = useStripe()
  const classes = useStyles()

  const schema = object().shape({
    SelectedCard: number()
      .nullable()
      .test('test', 'Please select a credit card.', val => {
        if (checkoutCart.orderTotal === 0) {
          return true
        } else {
          return val != null
        }
      }),
    User: object().shape({
      firstName: string()
        .required('First Name is required.')
        .max(35, 'First name cannot exceed 35 characters.'),
      lastName: string()
        .required('Last Name is required.')
        .max(35, 'First name cannot exceed 35 characters.'),
      phone: string()
        .required('Phone Number is required.')
        .matches(phoneRegExp, 'Phone Number must be 10 digits.'),
      cateringDeliveryInstructions: string().required('Delivery instructions are required.'),
      utensilsRequested: bool().required(''),
      cateringHeadcount: string()
        .nullable()
        .when('utensilsRequested', {
          is: true,
          then: val => val.required('Headcount is required.'),
          otherwise: val => val,
        }),
    }),
  })

  const confirmPayment = async () => {
    if (!elements || !stripe) {
      checkoutRejected('Payment System not initialized. Please contact support. ')
      return
    }

    const savedCardToUse = find(paymentMethods, method => method.id === selectedCard)?.providerId

    let newPaymentMethod

    const {
      validation: { isProcessable, error: validateError },
      externalClientSecret,
    } = await initializePayment(checkoutCart.orderId)
    if (!isProcessable) {
      checkoutRejected(validateError?.reason)
      return
    }

    if (checkoutCart.orderTotal === 0) {
      const { error: checkoutError } = await checkoutOrder(checkoutCart.orderId)
      if (checkoutError) {
        checkoutRejected(checkoutError.message)
      }
      checkoutComplete()
      return
    }

    let reqBody
    if (!isNewPaymentMethod(selectedCard)) {
      reqBody = {
        payment_method: savedCardToUse,
      }
    } else {
      if (newPaymentMethod) {
        //card created from subscription
        reqBody = {
          payment_method: newPaymentMethod,
        }
      } else {
        reqBody = {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        }
        if (shouldSaveCard) {
          //we want to be able to use this on a subscription, so off_session
          reqBody.setup_future_usage = 'off_session'
        }
      }
    }
    const { error } = await stripe.confirmCardPayment(externalClientSecret, reqBody)

    if (error) {
      checkoutRejected(error.message)
    } else {
      //let's give the webhook two seconds to process before going to order confirmation
      //eventually we'll use websockets to handle this transition
      setTimeout(checkoutComplete, 2000)
    }
  }

  //confirm payment
  useEffect(() => {
    if (waitingForProcessed) {
      confirmPayment()
    }
    //eslint-disable-next-line
  }, [waitingForProcessed])

  const handleSubmit = async (values, { setTouched }) => {
    checkoutStart(values)
    setTouched({})
  }

  if (isPaymentMethodsLoading || isNil(profile)) {
    return <SkeletonLoading />
  }

  return (
    <Formik
      initialValues={{
        SelectedCard: paymentMethods.length > 0 ? null : NEW_PAYMENT_METHOD_ID,
        User: {
          firstName: profile.firstName || '',
          lastName: profile.lastName || '',
          phone: profile.phone || '',
          cateringDeliveryInstructions: '',
        },
        saveCard: true,
        deliveryInstructions: curSavedLocation?.deliveryInstructions || '',
        utensilsRequested: false,
        cateringHeadcount: 1,
      }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ errors, handleBlur, handleChange, setFieldTouched, setFieldValue, touched, values }) => (
        <Form>
          <Box component="section" marginBottom={4}>
            <PanelHeader title="Account" />
            <UserInfo
              email={profile.username}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched}
              values={values}
            />
          </Box>
          {!isDeliveryDropoffLoading && (
            <Box paddingBottom={3}>
              <PanelHeader title="Delivery Details" />
              <CheckoutDeliveryDetails
                address={location.address}
                deliveryDate={cateringDeliveryDate}
                deliveryTime={cateringDeliveryTime}
              />
              <CateringDeliveryInstructions
                values={values}
                handleChange={handleChange}
                errors={errors}
                handleBlur={handleBlur}
                touched={touched}
              />
              <CateringUtensils
                values={values}
                handleChange={handleChange}
                errors={errors}
                handleBlur={handleBlur}
                touched={touched}
              />
            </Box>
          )}

          <Box component="section" marginBottom={4}>
            <PanelHeader title="Discounts" />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Coupon
                  applyCouponStart={applyCouponStart}
                  couponAmount={couponUsage ? couponUsage.couponAmount : 0}
                  couponApplied={couponUsage ? couponUsage.couponApplied : false}
                  couponCode={couponUsage ? couponUsage.couponCode : null}
                  error={errorApplyingCoupon}
                  isCheckoutCartLoading={isCheckoutCartLoading}
                  isCouponApplying={isCouponApplying}
                  isDailyLink={couponUsage ? couponUsage.isDailyLink : false}
                  willSubscribe={false}
                />
              </Grid>
              {checkoutCart.orderItems?.length > 0 && creditUsage?.creditBalance !== 0 && (
                <Grid item xs={12} md={6}>
                  <Credit
                    applyCreditStart={applyCreditStart}
                    availableCredits={availableCredits}
                    creditsApplied={creditUsage ? creditUsage.creditsApplied : false}
                    creditsUsed={creditUsage ? creditUsage.creditBalanceUsed : 0}
                    error={errorApplyingCredit}
                    isAvailableCreditsLoading={isAvailableCreditsLoading}
                    isCheckoutCartLoading={isCheckoutCartLoading}
                    isCreditApplying={isCreditApplying}
                    suggestedCredits={suggestedCredits}
                    willSubscribe={false}
                  />
                </Grid>
              )}
              {storedCurrencyUsage?.storedCurrencyBalance > 0 && (
                <Grid item xs={12} md={6}>
                  <Gifts
                    dropoffId={dropoffId}
                    isSubscribed={false}
                    loadCheckoutCartStart={loadCheckoutCartStart}
                    storedCurrencyUsage={storedCurrencyUsage}
                    isCheckoutCartLoading={isCheckoutCartLoading}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box component="section" marginBottom={4}>
            <PanelHeader title="Payment Methods" />
            {checkoutCart?.orderTotal === 0 ? (
              <Typography variant="h5">Your meal is covered!</Typography>
            ) : (
              <BillingInfo
                showSaveCard
                clearError={resetCheckoutError}
                paymentMethods={paymentMethods}
                paymentMethodsError={paymentMethodsError}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={value => {
                  handleChange(value)
                  resetCheckoutError()
                }}
                key="billing-form"
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                CardElement={CardElement}
              />
            )}
            {values.SelectedCard !== NEW_PAYMENT_METHOD_ID && (
              <Typography className={classes.manageCards} color={themeConWeb.color.two}>
                <Link href={profileRoute.path}>Manage Cards</Link>
              </Typography>
            )}
          </Box>
          <Hidden>
            <button type="submit" id="checkoutButton" />
          </Hidden>
        </Form>
      )}
    </Formik>
  )
}

const SkeletonLoading = () => (
  <>
    <Skeleton height={100} width="100%" />
    <Skeleton height={100} width="100%" />
    <Skeleton height={100} width="100%" />
  </>
)

CateringCheckoutForm.defaultProps = {
  profile: {},
}

const mapStateToProps = state => {
  const {
    programInfo,
    errorApplyingCoupon,
    errorApplyingCredit,
    isCouponApplying,
    isCreditApplying,
    waitingForProcessed,
    isLoadingProgram,
  } = state.checkout
  const {
    couponUsage,
    creditUsage,
    checkoutCart,
    isCheckoutCartLoading,
    storedCurrencyUsage,
  } = state.cart
  const { isDeliveryDropoffLoading, deliveryDropoff } = state.delivery
  const { availableCredits, isAvailableCreditsLoading } = state.creditHistory
  const location = selectCurrentLocationWithAddress(state)
  const { paymentMethodsError } = state.paymentMethods
  const profile = selectProfile(state)
  const suggestedCredits = selectSuggestedCredits(state)
  const selectedCard = selectSelectedCard(state)
  const shouldSaveCard = selectSaveCard(state)
  const curSavedLocation = selectCurrentSavedLocation(state)
  const cardPaymentDetail = selectCardPaymentDetail(state)
  const paymentMethods = selectAllPaymentMethods(state)
  const isPaymentMethodsLoading = selectPaymentMethodsIsLoading(state)

  return {
    profile,
    availableCredits,
    couponUsage,
    creditUsage,
    programInfo,
    checkoutCart,
    cardPaymentDetail,
    deliveryDropoff,
    location,
    waitingForProcessed,
    selectedCard,
    shouldSaveCard,
    errorApplyingCoupon,
    paymentMethods,
    paymentMethodsError,
    errorApplyingCredit,
    curSavedLocation,
    isAvailableCreditsLoading,
    isCheckoutCartLoading,
    isCouponApplying,
    isCreditApplying,
    storedCurrencyUsage,
    suggestedCredits,
    isLoadingProgram,
    isPaymentMethodsLoading,
    isDeliveryDropoffLoading,
  }
}

const mapDispatchToProps = {
  applyCouponStart,
  applyCreditStart,
  checkoutStart,
  checkoutComplete,
  checkoutRejected,
  loadCheckoutCartStart,
  toggleDeliveryInstructionsDialog,
  resetCheckoutError,
  loadIsSubscribedStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(CateringCheckoutForm)
