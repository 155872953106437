import React, { useEffect, useRef } from 'react'
import { Box, Grid, Hidden, Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import { number, object, string } from 'yup'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { find, isNil } from 'lodash'
import { connect } from 'react-redux'
import useSubscriptions from '../../hooks/useSubscriptions'
import { pushAnalyticsEvent } from '../../util/gtmUtils'

import { checkoutOrder, initializePayment } from '../../api/api'
import PanelHeader from '../common/PanelHeader'
import {
  applyCouponStart,
  applyCreditStart,
  checkoutStart,
  checkoutComplete,
  checkoutRejected,
  resetCheckoutError,
} from '../../redux/modules/checkout'
import { loadCheckoutCartStart } from '../../redux/modules/cart'
import { toggleDeliveryInstructionsDialog } from '../../redux/modules/location'
import {
  selectProfile,
  selectCardPaymentDetail,
  selectCurrentSavedLocation,
  selectSuggestedCredits,
  selectSelectedCard,
  selectSaveCard,
  selectAllPaymentMethods,
  selectCurrentLocationWithAddress,
  selectPaymentMethodsIsLoading,
} from '../../redux/modules/selectors'
import { phoneRegExp } from '../common/InputMasks'
import { loadIsSubscribedStart } from '../../redux/modules/subscriptions'
import { isNewPaymentMethod, NEW_PAYMENT_METHOD_ID } from '../../util/paymentMethods'
import CheckoutDeliveryDetails from '../common/CheckoutDeliveryDetails'
import { themeConWeb } from '../../util/modernThemeConweb'
import { profileRoute } from '../../routes/routes'
import PickupInstructions from '../common/PickupInstructions'
import DeliveryReserveFee from '../common/DeliveryReserveFee'
import BillingInfo from './BillingInfo'
import Coupon from './Coupon'
import Credit from './Credit'
import Gifts from './Gift'
import UserInfo from './UserInfo'
import Subscription from './Subscription'
import { shouldHideSubscription } from './checkout'

const useStyles = makeStyles(theme => ({
  manageCards: {
    fontSize: `${themeConWeb.fontSizes.base}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const ReserveCheckoutForm = ({
  // Props from parent
  dropoffId,

  // Props from redux
  isDeliveryDropoffLoading,
  deliveryDropoff,
  location,
  profile,
  applyCouponStart,
  couponUsage,
  creditUsage,
  applyCreditStart,
  resetCheckoutError,
  availableCredits,
  checkoutCart,
  cardPaymentDetail,
  checkoutStart,
  checkoutComplete,
  checkoutRejected,
  waitingForProcessed,
  selectedCard,
  shouldSaveCard,
  errorApplyingCoupon,
  paymentMethods,
  paymentMethodsError,
  errorApplyingCredit,
  curSavedLocation,
  isAvailableCreditsLoading,
  isCheckoutCartLoading,
  isCouponApplying,
  isCreditApplying,
  loadCheckoutCartStart,
  storedCurrencyUsage,
  suggestedCredits,
  isPaymentMethodsLoading,
  isSubscribed,
  willSubscribe,
}) => {
  const elements = useElements()
  const stripe = useStripe()
  const classes = useStyles()
  const isMounted = useRef(false)

  const { isSubscriptionEligible } = useSubscriptions()
  const hideSubscription = shouldHideSubscription(checkoutCart, isSubscribed, willSubscribe)

  const schema = object().shape({
    SelectedCard: number()
      .nullable()
      .test('test', 'Please select a credit card.', val => {
        if (checkoutCart.orderTotal === 0) {
          return true
        } else {
          return val != null
        }
      }),
    User: object().shape({
      firstName: string()
        .required('First Name is required.')
        .max(35, 'First name cannot exceed 35 characters.'),
      lastName: string()
        .required('Last Name is required.')
        .max(35, 'First name cannot exceed 35 characters.'),
      phone: string()
        .required('Phone Number is required.')
        .matches(phoneRegExp, 'Phone Number must be 10 digits.'),
    }),
  })

  const confirmPayment = async () => {
    if (!elements || !stripe) {
      checkoutRejected('Payment System not initialized. Please contact support. ')
      return
    }

    const savedCardToUse = find(paymentMethods, method => method.id === selectedCard)?.providerId

    let newPaymentMethod

    const {
      validation: { isProcessable, error: validateError },
      externalClientSecret,
    } = await initializePayment(checkoutCart.orderId)
    if (!isProcessable) {
      checkoutRejected(validateError?.reason)
      return
    }

    if (checkoutCart.orderTotal === 0) {
      const { error: checkoutError } = await checkoutOrder(checkoutCart.orderId)
      if (checkoutError) {
        checkoutRejected(checkoutError.message)
      }
      checkoutComplete()
      return
    }

    let reqBody
    if (!isNewPaymentMethod(selectedCard)) {
      reqBody = {
        payment_method: savedCardToUse,
      }
    } else {
      if (newPaymentMethod) {
        reqBody = {
          payment_method: newPaymentMethod,
        }
      } else {
        reqBody = {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        }
        if (shouldSaveCard) {
          reqBody.setup_future_usage = 'off_session'
        }
      }
    }
    const { error } = await stripe.confirmCardPayment(externalClientSecret, reqBody)

    if (error) {
      checkoutRejected(error.message)
    } else {
      setTimeout(checkoutComplete, 2000)
    }
  }

  const handleChangeSubscription = value => {
    if (isMounted.current) {
      loadCheckoutCartStart(dropoffId, value)
      pushAnalyticsEvent('Subscriptions', 'Clicked Subscription Checkbox')
    }
  }

  useEffect(() => {
    if (waitingForProcessed) {
      confirmPayment()
    }
    //eslint-disable-next-line
  }, [waitingForProcessed])

  const handleSubmit = async (values, { setTouched }) => {
    checkoutStart(values)
    setTouched({})
  }

  if (isPaymentMethodsLoading || isNil(profile) || isDeliveryDropoffLoading) {
    return <SkeletonLoading />
  }

  return (
    <Formik
      initialValues={{
        SelectedCard: paymentMethods.length > 0 ? null : NEW_PAYMENT_METHOD_ID,
        User: {
          firstName: profile.firstName || '',
          lastName: profile.lastName || '',
          phone: profile.phone || '',
        },
        saveCard: true,
        deliveryInstructions: curSavedLocation?.deliveryInstructions || '',
      }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ errors, handleBlur, handleChange, setFieldTouched, setFieldValue, touched, values }) => (
        <Form>
          <Box component="section" marginBottom={4}>
            <PanelHeader title="Account" />
            <UserInfo
              email={profile.username}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched}
              values={values}
            />
          </Box>
          {!isDeliveryDropoffLoading && (
            <Box paddingBottom={3}>
              <PanelHeader title="Delivery Details" />
              <CheckoutDeliveryDetails
                address={location.address}
                deliveryDate={deliveryDropoff.date}
                deliveryTime={deliveryDropoff.dropoffDisplay}
              />
              {!deliveryDropoff.isConfirmedReserveDropoff && (
                <DeliveryReserveFee reserveFee={deliveryDropoff.reserveDeliveryFee} />
              )}
              <PickupInstructions
                locationPickupInstructions={location.deliveryPickupInstructions}
              />
            </Box>
          )}

          <Box component="section" marginBottom={4}>
            <PanelHeader title="Discounts" />
            <Grid container spacing={3}>
              {!hideSubscription && isSubscriptionEligible && (
                <Grid item xs={12}>
                  <Subscription
                    isSubscribed={isSubscribed}
                    dropoffId={dropoffId}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    storedCurrencyUsage={storedCurrencyUsage}
                    values={values}
                    willSubscribe={willSubscribe}
                    onChange={handleChangeSubscription}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Coupon
                  applyCouponStart={applyCouponStart}
                  couponAmount={couponUsage ? couponUsage.couponAmount : 0}
                  couponApplied={couponUsage ? couponUsage.couponApplied : false}
                  couponCode={couponUsage ? couponUsage.couponCode : null}
                  error={errorApplyingCoupon}
                  isCheckoutCartLoading={isCheckoutCartLoading}
                  isCouponApplying={isCouponApplying}
                  isDailyLink={couponUsage ? couponUsage.isDailyLink : false}
                  willSubscribe={willSubscribe}
                />
              </Grid>
              {checkoutCart.orderItems?.length > 0 && creditUsage?.creditBalance !== 0 && (
                <Grid item xs={12} md={6}>
                  <Credit
                    applyCreditStart={applyCreditStart}
                    availableCredits={availableCredits}
                    creditsApplied={creditUsage ? creditUsage.creditsApplied : false}
                    creditsUsed={creditUsage ? creditUsage.creditBalanceUsed : 0}
                    error={errorApplyingCredit}
                    isAvailableCreditsLoading={isAvailableCreditsLoading}
                    isCheckoutCartLoading={isCheckoutCartLoading}
                    isCreditApplying={isCreditApplying}
                    suggestedCredits={suggestedCredits}
                    willSubscribe={willSubscribe}
                  />
                </Grid>
              )}
              {storedCurrencyUsage?.storedCurrencyBalance > 0 && (
                <Grid item xs={12} md={6}>
                  <Gifts
                    dropoffId={dropoffId}
                    isSubscribed={isSubscribed}
                    loadCheckoutCartStart={loadCheckoutCartStart}
                    storedCurrencyUsage={storedCurrencyUsage}
                    isCheckoutCartLoading={isCheckoutCartLoading}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box component="section" marginBottom={4}>
            <PanelHeader title="Payment Methods" />
            {checkoutCart?.orderTotal === 0 ? (
              <Typography variant="h5">Your meal is covered!</Typography>
            ) : (
              <BillingInfo
                showSaveCard
                clearError={resetCheckoutError}
                paymentMethods={paymentMethods}
                paymentMethodsError={paymentMethodsError}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={value => {
                  handleChange(value)
                  resetCheckoutError()
                }}
                key="billing-form"
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                CardElement={CardElement}
              />
            )}
            {values.SelectedCard !== NEW_PAYMENT_METHOD_ID && (
              <Typography className={classes.manageCards} color={themeConWeb.color.two}>
                <Link href={profileRoute.path}>Manage Cards</Link>
              </Typography>
            )}
          </Box>
          <Hidden>
            <button type="submit" id="checkoutButton" />
          </Hidden>
        </Form>
      )}
    </Formik>
  )
}

const SkeletonLoading = () => (
  <>
    <Skeleton height={100} width="100%" />
    <Skeleton height={100} width="100%" />
    <Skeleton height={100} width="100%" />
  </>
)

ReserveCheckoutForm.defaultProps = {
  profile: {},
}

const mapStateToProps = state => {
  const {
    programInfo,
    errorApplyingCoupon,
    errorApplyingCredit,
    isCouponApplying,
    isCreditApplying,
    waitingForProcessed,
    isLoadingProgram,
  } = state.checkout
  const {
    couponUsage,
    creditUsage,
    checkoutCart,
    isCheckoutCartLoading,
    storedCurrencyUsage,
    willSubscribe,
  } = state.cart
  const { isDeliveryDropoffLoading, deliveryDropoff } = state.delivery
  const { availableCredits, isAvailableCreditsLoading } = state.creditHistory
  const location = selectCurrentLocationWithAddress(state)
  const { paymentMethodsError } = state.paymentMethods
  const profile = selectProfile(state)
  const suggestedCredits = selectSuggestedCredits(state)
  const selectedCard = selectSelectedCard(state)
  const shouldSaveCard = selectSaveCard(state)
  const curSavedLocation = selectCurrentSavedLocation(state)
  const cardPaymentDetail = selectCardPaymentDetail(state)
  const paymentMethods = selectAllPaymentMethods(state)
  const isPaymentMethodsLoading = selectPaymentMethodsIsLoading(state)
  const { isSubscribed } = state.subscriptions

  return {
    profile,
    availableCredits,
    couponUsage,
    creditUsage,
    programInfo,
    checkoutCart,
    cardPaymentDetail,
    deliveryDropoff,
    location,
    waitingForProcessed,
    selectedCard,
    shouldSaveCard,
    errorApplyingCoupon,
    paymentMethods,
    paymentMethodsError,
    errorApplyingCredit,
    curSavedLocation,
    isAvailableCreditsLoading,
    isCheckoutCartLoading,
    isCouponApplying,
    isCreditApplying,
    storedCurrencyUsage,
    suggestedCredits,
    isLoadingProgram,
    isPaymentMethodsLoading,
    isDeliveryDropoffLoading,
    isSubscribed,
    willSubscribe,
  }
}

const mapDispatchToProps = {
  applyCouponStart,
  applyCreditStart,
  checkoutStart,
  checkoutComplete,
  checkoutRejected,
  loadCheckoutCartStart,
  toggleDeliveryInstructionsDialog,
  resetCheckoutError,
  loadIsSubscribedStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveCheckoutForm)
