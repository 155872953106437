import {
  getLocationManagerDataByLocationId,
  getLocationsManagersByLocationId,
  getLocationsManagerOffices,
  getLocationImages,
  editOfficeApi,
  removeOfficeApi,
} from '../../api/api'
import { createAsyncAction, FULFILLED, PENDING, REJECTED, createAction } from '../utils'
// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const LOCATIONS_USER_MANAGES_SET = 'foodsby/locationManager/LOCATIONS_USER_MANAGES_SET'
export const LOCATION_MANAGERS_SET = 'foodsby/locationManager/LOCATION_MANAGERS_SET'
export const LOCATION_MANAGER_OFFICES_SET = 'foodsby/locationManager/LOCATION_MANAGER_OFFICES_SET'
export const LOCATION_IMAGES_SET = 'foodsby/locationManager/LOCATION_IMAGES_SET'
export const EDIT_OFFICE = 'foodsby/locationManager/EDIT_OFFICE'
export const REMOVE_OFFICE = 'foodsby/locationManager/REMOVE_OFFICE'
export const PAGE_OFFICES_SET = 'foodsby/locationManager/PAGE_OFFICES_SET'

export const setLocationsUserManages = createAsyncAction(LOCATIONS_USER_MANAGES_SET)
export const setLocationManagers = createAsyncAction(LOCATION_MANAGERS_SET)
export const setLocationManagerOffices = createAsyncAction(LOCATION_MANAGER_OFFICES_SET)
export const setLocationImages = createAsyncAction(LOCATION_IMAGES_SET)
export const editOffice = createAsyncAction(EDIT_OFFICE)
export const removeOffice = createAsyncAction(REMOVE_OFFICE)
export const setPageOffices = createAction(PAGE_OFFICES_SET)

export const loadLocationsUserManagesStart = locationId => {
  return dispatch => {
    dispatch(setLocationsUserManages(getLocationManagerDataByLocationId(locationId)))
  }
}

export const loadLocationManagersStart = locationId => {
  return dispatch => {
    dispatch(setLocationManagers(getLocationsManagersByLocationId(locationId)))
  }
}

export const loadLocationManagerOfficesStart = locationId => {
  return dispatch => {
    dispatch(setLocationManagerOffices(getLocationsManagerOffices(locationId)))
  }
}

export const loadLocationImagesStart = locationId => {
  return dispatch => {
    dispatch(setLocationImages(getLocationImages(locationId)))
  }
}

export const editOfficeStart = (officeId, locationId, payload) => {
  return async dispatch => {
    await dispatch(editOffice(editOfficeApi(officeId, payload)))
    dispatch(loadLocationManagerOfficesStart(locationId))
  }
}

export const removeOfficeStart = (officeId, locationId) => {
  return async dispatch => {
    await dispatch(removeOffice(removeOfficeApi(officeId)))
    dispatch(loadLocationManagerOfficesStart(locationId))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [REJECTED(LOCATIONS_USER_MANAGES_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingLocationsUserManages: false,
      errorLoadingLocationsUserManages: action.payload ?? null,
      locationsUserManages: [],
      locationsUserManagesInitialized: true,
    }
  },
  [PENDING(LOCATIONS_USER_MANAGES_SET)]: state => {
    return {
      ...state,
      isLoadingLocationsUserManages: true,
    }
  },
  [FULFILLED(LOCATIONS_USER_MANAGES_SET)]: (state, action) => {
    return {
      ...state,
      locationsUserManages: action.payload ? action.payload : [],
      isLoadingLocationsUserManages: false,
      errorLoadingLocationsUserManages: undefined,
      locationsUserManagesInitialized: true,
    }
  },
  [REJECTED(LOCATION_MANAGERS_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingLocationManagers: false,
      errorLoadingLocationManagers: action.payload ?? null,
      locationManagers: [],
    }
  },
  [PENDING(LOCATION_MANAGERS_SET)]: state => {
    return {
      ...state,
      isLoadingLocationManagers: true,
    }
  },
  [FULFILLED(LOCATION_MANAGERS_SET)]: (state, action) => {
    return {
      ...state,
      locationManagers: action.payload ? action.payload : [],
      isLoadingLocationManagers: false,
      errorLoadingLocationManagers: undefined,
    }
  },
  [REJECTED(LOCATION_MANAGER_OFFICES_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingLocationOffices: false,
      errorLoadingLocationOffices: action.payload ?? null,
      locationOffices: [],
    }
  },
  [PENDING(LOCATION_MANAGER_OFFICES_SET)]: state => {
    return {
      ...state,
      isLoadingLocationOffices: true,
    }
  },
  [FULFILLED(LOCATION_MANAGER_OFFICES_SET)]: (state, action) => {
    return {
      ...state,
      locationOffices: action.payload ? action.payload.content : [],
      isLoadingLocationOffices: false,
      errorLoadingLocationOffices: undefined,
    }
  },
  [REJECTED(LOCATION_IMAGES_SET)]: (state, action) => {
    return {
      ...state,
      isLoadingLocationImages: false,
      errorLoadingLocationImages: action.payload ?? null,
      locationImages: [],
    }
  },
  [PENDING(LOCATION_IMAGES_SET)]: state => {
    return {
      ...state,
      isLoadingLocationImages: true,
    }
  },
  [FULFILLED(LOCATION_IMAGES_SET)]: (state, action) => {
    return {
      ...state,
      locationImages: action.payload ? action.payload.images : [],
      isLoadingLocationImages: false,
      errorLoadingLocationImages: undefined,
    }
  },
  [PENDING(EDIT_OFFICE)]: state => {
    return {
      ...state,
      isEditingOffice: true,
      errorEditingOffice: undefined,
    }
  },
  [FULFILLED(EDIT_OFFICE)]: state => {
    return {
      ...state,
      isEditingOffice: false,
      errorEditingOffice: undefined,
    }
  },
  [REJECTED(EDIT_OFFICE)]: state => {
    return {
      ...state,
      isEditingOffice: false,
      errorEditingOffice: 'Unable to edit office, please try again later or contact support.',
    }
  },
  [PENDING(REMOVE_OFFICE)]: state => {
    return {
      ...state,
      isRemovingOffice: true,
      errorRemovingOffice: undefined,
    }
  },
  [FULFILLED(REMOVE_OFFICE)]: state => {
    return {
      ...state,
      isRemovingOffice: false,
      errorRemovingOffice: undefined,
    }
  },
  [REJECTED(REMOVE_OFFICE)]: state => {
    return {
      ...state,
      isRemovingOffice: false,
      errorRemovingOffice: 'Unable to remove office, please try again later or contact support.',
    }
  },
  [PAGE_OFFICES_SET]: (state, action) => {
    return {
      ...state,
      pageOffices: action.payload,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  locationsUserManages: [],
  locationManagers: [],
  isLoadingLocationManagers: false,
  isLoadingLocationsUserManages: false,
  errorLoadingLocationsUserManages: undefined,
  errorLoadingLocationManagers: undefined,
  locationOffices: [],
  isLoadingLocationOffices: false,
  errorLoadingLocationOffices: undefined,
  locationsUserManagesInitialized: false,
  locationImages: [],
  isLoadingLocationImages: false,
  errorLoadingLocationImages: undefined,
  isEditingOffice: false,
  errorEditingOffice: undefined,
  isRemovingOffice: false,
  errorRemovingOffice: undefined,
  pageOffices: 0,
}

export default function locationManager(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
